.cm-s-default span.cm-a {
  background: #aad1f7;
}
.cm-s-default span.cm-b {
  background: #f9ca69;
}
.cm-s-default span.cm-c {
  color: #007a09;
}

.cm-s-default span.cm-g1 {
  background: #d2f854;
}
.cm-s-default span.cm-g2 {
  background: #9ec70c;
}
.cm-s-default span.cm-g3 {
  background: #ecc9f7;
}
.cm-s-default span.cm-g4 {
  background: #54b70b;
}
.cm-s-default span.cm-g5 {
  background: #b688cf;
}

.cm-s-default span.cm-err {
  background: #ff4300;
}
